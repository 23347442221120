<template>
  <v-container fluid class="career-login height-100">
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-card-title
            class="
              d-flex
              justify-space-between
              wide
              white--text
              elevation-5
              primary-bkg
              py-7
            "
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="8"
                class="d-flex justify-center justify-sm-start"
              >
                <v-img
                  width="300"
                  max-width="300"
                  :src="logoSrc"
                  contain
                  class="ml-3 h-40"
                ></v-img>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center justify-sm-end"
              >
                <div class="login-title pt-4 pt-sm-0 pr-md-4">Resume Tool</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              You are an admin user. You must confirm your email to proceed.
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <p class="text-body-1 text--primary">
              Check your email or request a new confirmation email.
            </p>
            <v-btn
                class="mt-2 mb-2"
              outlined
              @click="sendConfirmEmail()"
              :disabled="!confirmEmailEnabled"
              :loading="confirmEmailLoading"
              >{{ confirmEmailButtonText }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { Env } from "../../environment/EnvironmentFactory";
import * as login from "../../api/LoginApi";

// @ is an alias to /src
export default {
  name: "ConfirmEmailRequired",
  data: () => ({
    confirmEmailButtonText: "Re-Send Confirmation Email",
    confirmEmailEnabled: true,
    confirmEmailLoading: false,
    logoSrc: "",
  }),
  async mounted() {
    Env()
      .GetLogoFilename()
      .then((f) => {
        this.logoSrc = f;
      });
  },
  methods: {
    async sendConfirmEmail() {
      this.confirmEmailLoading = true;
      this.confirmEmailEnabled = false;

      const confirmEmailResp = await login.SendConfirmEmail();
      if (!confirmEmailResp.success) {
        this.emailErrorText = "Unable to send a confirmation email.";
        this.confirmEmailEnabled = true;
      } else {
        this.confirmEmailButtonText = "Confirmation Email Sent";
        this.confirmEmailEnabled = false;
      }
      this.confirmEmailLoading = false;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}

.career-login {
  background-color: #f4f4f4;
}

.primary-bkg {
  background-color: var(--v-primary-base);
}

.h-40 {
  max-height: 40px;
}
</style>
